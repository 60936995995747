import { TToastData, TToastOptions, TToastsName } from './Notification.domain';

export const getToastData = (
    type: TToastsName,
    options?: TToastOptions
): TToastData | undefined => {
    switch (type) {
        case 'shopping_cart':
            return {
                title: 'Shopping Cart',
                label: `Don't forget you have ${options?.activitiesCount || ''} items in your Cart`,
                duration: 4,
                image: 'cart',
                btnLink: '/cart/',
            };
        case 'promocode':
            return {
                title: options?.promoName || '',
                label: `Thanks for visiting. Today’s discount has been applied to your shopping cart.`,
                duration: 4,
                image: 'promo',
            };
        case 'expired_cart':
            return {
                title: 'Your Cart is Empty',
                label: 'We have sent you back to the last activity you have viewed',
                duration: 7,
                image: 'promo',
            };
        default:
            return undefined;
    }
};
