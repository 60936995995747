import instance, { apiImageInstance } from '@/common/service/api/Axios';
import { ApiUrls } from '@/common/domain/Endpoints.domain';
import {
    IActivityDatelessResponse,
    TActivityParams,
    TImageById,
    TImageExcerpt,
    TImageItem,
} from './Activity.domain';

export const fetchActivityDateless = (
    destination_id: string,
    category_id?: string | number
): Promise<IActivityDatelessResponse> => {
    return instance
        .get<IActivityDatelessResponse>(ApiUrls.activity.dateless, {
            params: {
                destination_id,
                category_id,
            },
        })
        .then(({ data }) => data);
};

export const fetchActivity = (params: TActivityParams): Promise<IActivityDatelessResponse> => {
    return instance
        .get<IActivityDatelessResponse>(ApiUrls.activity.root, { params })
        .then(({ data }) => data);
};

export const fetchUserImagesInSearch = (
    activity_ids: string
): Promise<TImageExcerpt> | TImageExcerpt => {
    if (!activity_ids) return {} as TImageExcerpt;

    return apiImageInstance
        .get<TImageExcerpt>(ApiUrls.photo.excerpt, { params: { activity_ids: activity_ids } })
        .then(({ data }) => {
            if (data && typeof data === 'object') {
                return data;
            }
            return {};
        })
        .catch((err) => {
            console.error(err);
            return {} as TImageExcerpt;
        });
};

export const fetchUserImagesById = (id: string): Promise<TImageItem[]> => {
    return apiImageInstance
        .get<TImageById>(ApiUrls.photo.root, { params: { activity_id: id } })
        .then(({ data }) => {
            if (data && data?.items?.length) {
                return data.items;
            }
            return [] as TImageItem[];
        })
        .catch((err) => {
            console.error(err);
            return [] as TImageItem[];
        });
};

export const fetchActivityDatelessLandmarks = (
    landmark_id: string
): Promise<IActivityDatelessResponse> => {
    return instance
        .get<IActivityDatelessResponse>(ApiUrls.activity.dateless, {
            params: {
                landmark_id,
            },
        })
        .then(({ data }) => data);
};

export interface IImagesWithPlaceholdersRes {
    [key: string]: {
        id: string;
        placeholder?: string;
    }[];
}

export const getCardImagesWithPlaceholders = async (
    ids: string[]
): Promise<IImagesWithPlaceholdersRes> => {
    try {
        if (!ids) return {};

        const size = 50;
        const subarray = [];

        for (let i = 0; i < Math.ceil(ids.length / size); i++) {
            subarray[i] = ids.slice(i * size, i * size + size);
        }

        const data = await Promise.all(
            subarray.map((i) =>
                apiImageInstance
                    .get<TImageExcerpt>(ApiUrls.photo.excerpt, {
                        params: { activity_ids: i.join(',') },
                    })
                    .then(({ data }) => data)
                    .catch(() => ({}))
            )
        );

        const photoIds: string[] = [];

        data.forEach((i) => {
            Object.values(i).forEach((v) => {
                photoIds.push(...v.map(({ id }) => id));
            });
        });

        const placeholders = await getPlaceholders(photoIds, 'photo');

        return data.reduce((acc: IImagesWithPlaceholdersRes, curr) => {
            const newFields: IImagesWithPlaceholdersRes = {};
            Object.keys(curr).forEach((i) => {
                const id = i.split('_')[1];
                if (id) {
                    newFields[id] = ((curr as TImageExcerpt)[i] || []).map(({ id }) => ({
                        id,
                        placeholder: placeholders[id] || '',
                    }));
                }
            });

            return { ...acc, ...newFields };
        }, {});
    } catch (_) {
        return {};
    }
};

export const getPlaceholders = async (ids: string[], type: string) => {
    try {
        if (!ids) return {};

        const size = 50;
        const subarray = [];

        for (let i = 0; i < Math.ceil(ids.length / size); i++) {
            subarray[i] = ids.slice(i * size, i * size + size);
        }

        const data = await Promise.all(
            subarray.map((i) =>
                apiImageInstance
                    .get<{
                        [key: string]: string;
                    }>(`${ApiUrls.photo.placeholder}/${type}`, {
                        params: { ids: i.join(',') },
                    })
                    .then(({ data }) => data)
                    .catch(() => ({}))
            )
        );

        let placeholders: {
            [key: string]: string;
        } = {};
        data.forEach((i) => {
            placeholders = {
                ...placeholders,
                ...i,
            };
        });

        return placeholders;
    } catch (_) {
        return {};
    }
};
