/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-prototype-builtins */
export const isEmpty = (value: any) => {
    if (value == null) {
        return true;
    }
    if (Array.isArray(value) || typeof value == 'string' || typeof value.splice == 'function') {
        return !value.length;
    }

    for (const key in value) {
        if (value.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
};
