import { css } from 'styled-components';

export const iconPresets = css`
    display: flex;
    border: none;
    position: relative;
    background: transparent;
    padding: 5px 5px 0px 0px;
    cursor: pointer;
`;

export const chipPresets = css`
    width: 100%;
    height: 100%;
    cursor: pointer;
`;

export const buttonPresets = css`
    cursor: pointer;
    width: 100%;
    height: 100%;
`;

export const spinnerPresets = css`
    height: 100%;
    width: 100%;
    border-radius: 15px;
    background-color: transparent;
    color: transparent;
`;

export const loaderPresets = css`
    width: 40px;
    height: 40px;
`;

export const socialIconPresets = css`
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - 40px);
`;

export const Presets = {
    icon: iconPresets,
    chips: chipPresets,
    button: buttonPresets,
    spinner: spinnerPresets,
    loader: loaderPresets,
    socialIcon: socialIconPresets,
};
