import { IRecommendsResponse, TRecommendsParams } from './Recommends.domain';
import instance from '@/common/service/api/Axios';
import { ApiUrls } from '@/common/domain/Endpoints.domain';
import { TActivityRecommends } from '@/entities/Activity/domain/Activity.domain';

export const fetchRecommends = async (params: TRecommendsParams): Promise<IRecommendsResponse> => {
    const { data } = await instance.get<IRecommendsResponse>(ApiUrls.destination.popular, {
        params,
    });
    return data;
};

export async function fetchActivityRecommends(params: TRecommendsParams) {
    const { data } = await instance.get<TActivityRecommends>(ApiUrls.activity.recommended.root, {
        params,
    });

    if (data && typeof data === 'object' && Array.isArray(data.items)) {
        return data.items;
    }
    throw new Error('Error');
}
