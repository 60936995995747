import Palette from '@/styles/Themes/Palette';
import { TColors } from '../Theme.domain';

export const colors: TColors = {
    primary: Palette.green.normal,
    secondary: Palette.blue.normal,
    background: Palette.white.normal,
    mint: Palette.mint.normal,
    ash: Palette.ash.normal,
    shades: {
        green1: Palette.green.normal,
        green2: '#268F53',
        gray1: Palette.gray.black,
        gray2: Palette.gray.dark,
        gray3: Palette.gray.light,
        gray4: Palette.gray.white,
        hoverLight: '#268F53',
        hoverDark: '#00D767',
    },
    typography: {
        gray1: Palette.gray.black,
        gray2: Palette.gray.dark,
        white: Palette.white.normal,
    },
    state: {
        info: '#5C8FDC',
        success: '#50B061',
        warning: '#E9C052',
        error: '#EC4652',
    },
    extra: {
        arrow: Palette.dark.dark5,
        arrowHover: Palette.dark.dark3,
        arrowFill: Palette.green.normal,
    },
    white: Palette.white.normal,
    black: Palette.black.normal,
    gray: Palette.gray.normal,
    grays: {
        white: Palette.gray.white,
        lighter: Palette.gray.lighter,
        light: Palette.gray.light,
        normal: Palette.gray.normal,
        dark: Palette.gray.dark,
        darker: Palette.gray.darker,
        strong: Palette.gray.strong,
        black: Palette.gray.black,
        error: Palette.gray.normal,
    },
    dark: Palette.dark.normal,
    darks: {
        white: Palette.dark.white,
        lighter: Palette.dark.lighter,
        light: Palette.dark.light,
        normal: Palette.dark.normal,
        dark: Palette.dark.dark,
        darker: Palette.dark.darker,
        strong: Palette.dark.strong,
        black: Palette.dark.black,
        error: Palette.dark.dark,
    },
    blue: Palette.blue.normal,
    blues: {
        white: Palette.blue.white,
        lighter: Palette.blue.lighter,
        light: Palette.blue.light,
        normal: Palette.blue.normal,
        dark: Palette.blue.dark,
        darker: Palette.blue.darker,
        strong: Palette.blue.strong,
        black: Palette.blue.black,
        error: Palette.blue.dark,
    },
    green: Palette.green.normal,
    greens: {
        white: Palette.green.white,
        lighter: Palette.green.lighter,
        light: Palette.green.light,
        normal: Palette.green.normal,
        dark: Palette.green.dark,
        darker: Palette.green.darker,
        strong: Palette.green.strong,
        black: Palette.green.black,
        error: Palette.green.dark,
    },
    gradient: 'linear-gradient(90deg, #03D9B1 0%, #1CBBD9 100%)',
    gradients: {
        light: 'linear-gradient(90deg, #03D9B1 0%, #1CBBD9 100%)',
        dark: 'linear-gradient(90deg, #06C9A4 0%, #00A2C1 100%)',
    },
    transparent: 'transparent',
};
