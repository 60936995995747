import { ApiUrls } from '@/common/domain/Endpoints.domain';
import instance from '@/common/service/api/Axios';
import { IGetOrderResponse } from '../domain/Checkout.domain';

export const fetchByAccessKey = async (
    accessKey: string,
    isFull?: boolean
): Promise<IGetOrderResponse> => {
    return instance
        .get<IGetOrderResponse>(ApiUrls.order.root, {
            params: { accesskey: accessKey, isFull: isFull && 1 },
        })
        .then(({ data }) => {
            return data;
        });
};
