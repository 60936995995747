import { IState } from './../../../domain/State.domain';
import { ApiUrls } from '@/common/domain/Endpoints.domain';
import instance from '@/common/service/api/Axios';
import { IPartnersInfoDestination } from '@/common/service/api/Partners/Partners.domain';
import { IDestination } from '@/entities/Destination/domain/Destination.domain';
import destinationsCache from 'scripts/cache/destinations.json';
import { TDestinationList, TDestinationPopular } from './Destination.domain';
import { URL } from '@/common/app/utils/urlsUtils';
import { IMeta } from '@/common/domain/Meta.domain';

interface IApiFetchDestinations extends Partial<IPartnersInfoDestination> {
    id: string;
    content_only: string;
    name: string;
    state: IState;
    total_activities: number;
    meta: IMeta;
    slug: string;
    categories: string;
    hidden_categories: string;
    url?: string;
    url_partners?: string;
}

export const getDestinationPopular = (): Promise<TDestinationPopular> => {
    return instance.get<TDestinationPopular>(ApiUrls.destination.popular).then(({ data }) => data);
};

export const fetchDestinations = async (): Promise<IApiFetchDestinations[]> => {
    if (destinationsCache) {
        return destinationsCache;
    }

    return instance.get(ApiUrls.destination.root);
};

export const getDestinationList = (): Promise<TDestinationList> => {
    return instance.get<TDestinationList>(ApiUrls.destination.root).then(({ data }) => data);
};

export const getVisitorsGuide = async (id: string) => {
    try {
        const response = await fetch(`${URL.api}/destination/${id}`);
        return await response.json();
    } catch (e) {
        console.log(e);
    }
};

export const mapDestinations = (destinations: IApiFetchDestinations[]): IDestination[] => {
    return destinations.map((destination) => {
        return {
            id: destination.id,
            content_only: destination.content_only,
            name: destination.name,
            slug: destination.slug,
            state: {
                name: destination.state.name,
                code: destination.state.code,
            },
            total_activities: destination.total_activities,
            meta: {
                title: destination.meta.title,
                description: destination.meta.description,
            },
            categories: destination.categories,
            hidden_categories: destination.hidden_categories,
        };
    });
};

export const fetchAppDestinations = async () => {
    const data = await fetchDestinations();

    return mapDestinations(data);
};
