/**
 *
 * Simple Local Storage
 *  for large data
 *
 */

import { getCS as getCookie } from '@/common/service/storage/CookieStorage/CookieStorage';

export const LS_STATE = '_ts__state';
export const LS_TOKEN_CART = '_ts__state-token-cart';
export const LS_WISH_LIST = '_ts__state-wish-list';
export const LS_CONTACT_INFO = '_ts__contact-info'; // Don't change contact_info fields, important for GA
export const LS_CART_ITEM_PRODUCT_IDS = '_ts__cart-item-product-ids';
export const LS_COGNITO_USER = '_ts__cognito-user';
export const LS_COGNITO_GLOBAL_LOADING = '_ts__cognito-global-loading';
export const LS_COGNITO_SIGN_OUT = '_ts__cognito-sign-out';
export const LS_COGNITO_REDIRECT = '_ts__cognito-redirect';
export const LS_COGNITO_SIGN_UP = '_ts__cognito-sign-up';
export const LS_RECENT_SEARCH = 'recent_search';

const LocalStorage = {
    get: (name, returnDefault = null) => {
        if (typeof name !== 'string' || name.length <= 0) {
            throw new Error('Invalid key.');
        }
        const value = window.localStorage.getItem(name);
        if (value && typeof value === 'string' && value !== '[]') {
            const parsed = JSON.parse(value);
            if (parsed) {
                return parsed;
            }
        } else if (name === LS_CONTACT_INFO && getCookie('iterableEndUserId')) {
            set(LS_CONTACT_INFO, {
                email: getCookie('iterableEndUserId'),
            });
            const user_info = {
                email: getCookie('iterableEndUserId'),
            };
            return user_info;
        }

        return returnDefault;
    },
    set: (name, data = null) => {
        if (typeof name !== 'string' || name.length <= 0) {
            throw new Error('Invalid key.');
        }
        if (data === null) {
            return false;
        }
        window.localStorage.setItem(name, JSON.stringify(data));
        return true;
    },
    remove: (name) => window.localStorage.removeItem(name),
    clear: () => window.localStorage.clear(),
};

const getStorage = () => {
    if (window.localStorage !== undefined) {
        return LocalStorage;
    }

    throw new Error('Storage not defined');
};

export const get = (...args) => getStorage().get(...args);
export const set = (...args) => getStorage().set(...args);
export const remove = (...args) => getStorage().remove(...args);
export const clear = (...args) => getStorage().clear(...args);

export const loadState = () => {
    try {
        const storage = getStorage();
        const state = storage.get(LS_STATE, undefined);
        return state;
    } catch (err) {
        try {
            if (err.code !== DOMException.QUOTA_EXCEEDED_ERR) {
                console.log(err); // eslint-disable-line no-console
            }
        } catch (ign) {
            // ignore
        }
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const storage = getStorage();
        return storage.set(LS_STATE, state);
    } catch (err) {
        try {
            if (err.code !== DOMException.QUOTA_EXCEEDED_ERR) {
                console.log(err); // eslint-disable-line no-console
            }
        } catch (ign) {
            // ignore
        }
        return false;
    }
};

export const setRecentChipsToLS = (searchItem) => {
    const dateFrom =
        typeof searchItem.dateFrom === 'string'
            ? searchItem.dateFrom
            : searchItem.dateFrom.toJSON();
    const dateTo =
        typeof searchItem.dateTo === 'string' ? searchItem.dateTo : searchItem.dateTo.toJSON();

    let storage = JSON.parse(window.localStorage.getItem(LS_RECENT_SEARCH)) || [];

    if (storage.length > 0) {
        storage = storage.filter((item) => {
            if (searchItem.destination?.id !== item?.destination?.id) {
                return true;
            }
            if (
                new Date(dateFrom).getTime() !== new Date(item.dateFrom).getTime() ||
                new Date(dateTo).getTime() !== new Date(item.dateTo).getTime()
            ) {
                return true;
            }
            if (
                searchItem.searchTypeExperience &&
                item.searchTypeExperience === searchItem.searchTypeExperience &&
                String(item.category?.id) == String(searchItem.category?.id)
            ) {
                return false;
            } else if (
                !searchItem.searchTypeExperience &&
                item.searchTypeExperience === searchItem.searchTypeExperience &&
                item.companyUrl === searchItem.companyUrl
            ) {
                return false;
            }
            return true;
        });
    }
    if (storage.length >= 4) {
        storage.pop();
        storage.unshift(searchItem);
    } else {
        storage.unshift(searchItem);
    }
    window.localStorage.setItem(LS_RECENT_SEARCH, JSON.stringify(storage));
};
