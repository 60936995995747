import { isValidPhoneNumber } from 'libphonenumber-js';

export const isValidEmail = (email: string) => {
    return email.trim() == ''
        ? false
        : /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email);
};

export const isValidName = (value: string) => {
    return value.trim() == '' ? false : /^[a-zA-Z']+([a-zA-Z-' ]+[a-zA-Z'])?$/.test(value);
};

export const isValidPhone = (phone: string) => {
    return isValidPhoneNumber(phone);
};
