import {
    IAvailability,
    IDaysAvailability,
} from '../../service/api/Availability/Availability.domain';

export const mergeAvailability = <T extends { id: string | number }>(
    activities: T[],
    availability: IAvailability[]
): (T & { days_availability?: IDaysAvailability })[] => {
    return activities.map((value) => {
        const match = availability.find((v) => Number(value?.id) === v.id);
        if (match?.days_availability) {
            const {
                days_availability,
                price,
                price_strike_out,
                price_subunits,
                price_strike_out_subunits,
            } = match;
            return {
                ...value,
                days_availability,
                price,
                price_strike_out,
                price_subunits,
                price_strike_out_subunits,
            };
        } else {
            return value;
        }
    });
};
