import { getLS } from '@/common/service/storage';
import { LS_CONTACT_INFO } from 'utils/ls';
import {
    isValidEmail as validateEmail,
    isValidName,
    isValidPhone as validatePhone,
} from '@/common/app/utils/typeUtils';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export interface IInputState {
    value: string;
    isValid: boolean;
    showError: boolean;
}

export type TContactForm = {
    firstName: IInputState;
    setFirstName: Dispatch<SetStateAction<IInputState>>;
    lastName: IInputState;
    setLastName: Dispatch<SetStateAction<IInputState>>;
    email: IInputState;
    setEmail: Dispatch<SetStateAction<IInputState>>;
    phone: IInputState;
    setPhone: Dispatch<SetStateAction<IInputState>>;
    phoneSecondary: IInputState;
    setPhoneSecondary: Dispatch<SetStateAction<IInputState>>;
    checkSendSms: boolean;
    setCheckSendSms: Dispatch<SetStateAction<boolean>>;
    isValidForm: boolean;
    setPhoneChanged: Dispatch<SetStateAction<boolean>>;
    phoneChanged: boolean;
};

const defaultInputState = {
    value: '',
    isValid: true,
    showError: false,
};

export const DEFAULT_CONTACT_FORM = {
    firstName: { ...defaultInputState },
    setFirstName: () => {
        return;
    },
    lastName: { ...defaultInputState },
    setLastName: () => {
        return;
    },
    email: { ...defaultInputState },
    setEmail: () => {
        return;
    },
    phone: { ...defaultInputState },
    setPhone: () => {
        return;
    },
    phoneSecondary: { ...defaultInputState },
    setPhoneSecondary: () => {
        return;
    },
    checkSendSms: true,
    setCheckSendSms: () => {
        return;
    },
    isValidForm: true,
    phoneChanged: false,
    setPhoneChanged: () => {
        return;
    },
};

export const useUserInfo = (): TContactForm => {
    const [firstName, setFirstName] = useState<IInputState>(DEFAULT_CONTACT_FORM.firstName);
    const [lastName, setLastName] = useState<IInputState>(DEFAULT_CONTACT_FORM.lastName);
    const [email, setEmail] = useState<IInputState>(DEFAULT_CONTACT_FORM.email);
    const [phone, setPhone] = useState<IInputState>(DEFAULT_CONTACT_FORM.phone);
    const [phoneSecondary, setPhoneSecondary] = useState<IInputState>(
        DEFAULT_CONTACT_FORM.phoneSecondary
    );
    const [checkSendSms, setCheckSendSms] = useState<boolean>(DEFAULT_CONTACT_FORM.checkSendSms);
    const [phoneChanged, setPhoneChanged] = useState<boolean>(false);

    const isValidForm =
        !!firstName.value &&
        firstName.isValid &&
        !!lastName.value &&
        lastName.isValid &&
        !!email.value &&
        email.isValid &&
        !!phone.value &&
        phone.isValid;

    useEffect(() => {
        const data = getLS(LS_CONTACT_INFO) || {};

        if (data) {
            const firstName = data.firstName;
            const lastName = data.lastName;
            const email = data.email;
            const phone =
                data.phone?.length && data.phone[0] !== '+' ? `+${data.phone}` : data.phone;

            if (firstName) {
                setFirstName({
                    value: firstName,
                    isValid: isValidName(firstName),
                    showError: true,
                });
            }

            if (lastName) {
                setLastName({
                    value: lastName,
                    isValid: isValidName(lastName),
                    showError: true,
                });
            }

            if (email) {
                setEmail({
                    value: email,
                    isValid: validateEmail(email),
                    showError: true,
                });
            }

            if (phone) {
                setPhone({
                    value: phone,
                    isValid: validatePhone(phone),
                    showError: true,
                });
            }
        }
    }, []);

    return {
        firstName,
        lastName,
        email,
        phone,
        phoneSecondary,
        checkSendSms,
        isValidForm,
        phoneChanged,
        setFirstName,
        setLastName,
        setEmail,
        setPhone,
        setPhoneSecondary,
        setCheckSendSms,
        setPhoneChanged,
    };
};
