import { ApiUrls } from '@/common/domain/Endpoints.domain';
import instance from '@/common/service/api/Axios';
import { TItem } from '../domain/Cart.domain';

type RemoveCartResponse = {
    success: boolean;
    errors: string[];
};

export const deleteCartItem = (params: TItem): Promise<RemoveCartResponse> => {
    return instance
        .get<RemoveCartResponse>(ApiUrls.cart.deleteItem, { params })
        .then(({ data }) => data);
};
