export function getScrollbarWidth() {
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll';
    outer.style.msOverflowStyle = 'scrollbar';

    if (!document.body) {
        return 0;
    }

    document.body.appendChild(outer);

    const inner = document.createElement('div');
    outer.appendChild(inner);

    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
}

export const isAndroid = () =>
    typeof navigator !== 'undefined' && navigator.userAgent.toLowerCase().includes('android');

export const isMobile = () =>
    typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string'
        ? false
        : /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows.*?Phone|Android|iP(ad|od|hone)/i.test(
              navigator.userAgent
          );

export const isSafari = () =>
    typeof navigator !== 'undefined' && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const isChrome = () =>
    typeof navigator !== 'undefined' && /Google Inc/.test(navigator.vendor);

export const isEDGE = () => typeof navigator !== 'undefined' && /Edge/.test(navigator.userAgent);

export const isIE = () =>
    (typeof navigator !== 'undefined' && navigator.userAgent.search(/MSIE/) > 0) ||
    (typeof navigator !== 'undefined' && navigator.userAgent.search(/NET CLR /) > 0) ||
    (typeof window !== 'undefined' &&
        !!window.MSInputMethodContext &&
        typeof document !== 'undefined' &&
        !!document.documentMode);
