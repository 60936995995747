import { LocalStorageTypes } from '@/common/service/storage';
import { CustomThemeNames } from './Theme.domain';
import { cssVariablesValues } from './cssVars';

const createCssVars = (mode: string) => {
    const index = mode === CustomThemeNames.dark ? 1 : 0;
    const result: Array<[string, string]> = [];

    for (const key in cssVariablesValues) {
        result.push([key, cssVariablesValues[key][index]]);
    }

    return result;
};

export const styleVariables = {
    [CustomThemeNames.light]: createCssVars(CustomThemeNames.light),
    [CustomThemeNames.dark]: createCssVars(CustomThemeNames.dark),
};

export const changeStyleVariables = (styles: Array<[string, string]>): void => {
    if (typeof window === 'undefined') return;

    const root = document.documentElement.style;

    styles.forEach((v) => root.setProperty(v[0], v[1]));
};

export const defaultStyleVariables: string = styleVariables[CustomThemeNames.light].reduce(
    (res, [key, val]) => {
        return res + `${key}: ${val};`;
    },
    ''
);

const ThemeScript = `(function(){
    const isDark = () => {
        const persistedColorPreference = window
            .localStorage
            .getItem('${LocalStorageTypes.LS_THEME_MODE}');
        
        if (JSON.parse(persistedColorPreference) === 'dark') {
            return true;
        }
        return false;
    };
    
    if(!isDark()){
        return;
    };
    
    const dark = ${JSON.stringify(styleVariables[CustomThemeNames.dark])};

    const st = document.documentElement.style;
    
    dark.forEach(v=>st.setProperty(v[0], v[1]));
})()`;

export default ThemeScript;
