import { ApiUrls } from '@/common/domain/Endpoints.domain';
import { PromocodeParams, PromocodeProps, TApplyPromoParams, TPromoRes } from './Promocode.domain';
import instance from '@/common/service/api/Axios';

export const getPromocodeName = (promocode: string) => {
    return instance
        .get<PromocodeProps>(ApiUrls.promocode.name, { params: { promocode } })
        .then(({ data }) => data);
};

export const applyPromocode = (params: TApplyPromoParams) => {
    return instance
        .get<TPromoRes>(ApiUrls.promocode.apply, { params })
        .then(({ data }) => data)
        .catch((err) => {
            throw new Error('Promocode apply error!', err);
        });
};

export const removePromocode = (session_id: string) => {
    return instance
        .get<TPromoRes>(ApiUrls.promocode.remove, { params: { session_id } })
        .then(({ data }) => data);
};

export const activity = ({ promocode, ids }: PromocodeParams) => {
    return instance
        .get(ApiUrls.promocode.activity, {
            params: { promocode: promocode, ids: Array.isArray(ids) ? ids.join(',') : ids },
        })
        .then(({ data }) => {
            if (Array.isArray(data)) {
                return data;
            }

            return [];
        });
};
