export const ApiUrls = {
    public: {
        root: '/public',
        blog: '/public/blog',
        story: '/public/story',
        faq: '/public/faq',
        page: '/public/page',
        category_description: '/public/category_description',
    },
    destination: {
        root: '/destination',
        popular: '/destination/popular',
    },
    categories: {
        root: '/categories',
    },
    activity: {
        root: '/activity',
        availability: {
            root: '/activity/availability',
            short: '/activity/availability/short',
            month: '/activity/availability/month',
        },
        dateless: '/activity/dateless',
        recommended: {
            root: '/activity/recommended',
        },
    },
    review: {
        root: '/review',
        list: '/review/list',
        add: '/review/add',
        recent: '/review/recent',
    },
    partners: {
        root: '/partner',
        home: '/partner/home?',
    },
    photo: {
        root: '/photo',
        excerpt: '/photo/excerpt',
        placeholder: '/placeholder',
    },
    itinerary: {
        root: '/itinerary',
    },
    promocode: {
        activity: '/promo-code/activity',
        apply: '/promo-code/apply',
        name: '/promo-code/name',
        remove: '/promo-code/remove',
    },
    cart: {
        add: '/cart/add',
        get: '/cart/get',
        delete: '/cart/delete',
        deleteItem: '/cart/delete-item',
        extend: '/cart/extend',
    },
    config: {
        root: '/config',
    },
    order: {
        root: '/order',
    },
    checkout: {
        root: '/checkout',
        saveUser: '/checkout/save-user',
        token: '/checkout/token',
        pay: '/checkout/pay',
    },
    story: {
        root: '/story',
    },
    landmark: {
        root: '/landmark',
        photo: 'landmark_photo',
    },
    endpoint: {
        root: '/endpoint',
    },
};
