import { fetchActivityRecommends } from '@/common/service/api/Recommends/Recommends';
import { TRecommendsParams } from '@/common/service/api/Recommends/Recommends.domain';
import { IRecommendsItem } from '../ui/featuredActivitiesPreview/FeaturedActivitiesPreview.types';

export const getRecommends = async (params: TRecommendsParams): Promise<IRecommendsItem[]> => {
    try {
        const response = await fetchActivityRecommends(params);

        const data: IRecommendsItem[] = response.map((item) => ({
            ...(item as IRecommendsItem),
            numberOfItems: response.length,
        }));

        return data || [];
    } catch (error) {
        return [];
    }
};
