import cookies from 'react-cookies';
import { TCookieData, TPath } from '../Storage.domain';
export const CS_PROMOCODE = 'ts_promocode';
import { toDate, addHours, addMinutes } from 'date-fns';
export const CS_TOKEN_CART = 'ts_token_cart';
import { CookiesTypes } from '../Storage.domain';

const THREE_DAYS = 72;

// Convert this to a useCookieStorage hook.
const CookiesStorage = {
    get: (name: string, returnDefault?: string): TCookieData => {
        const value = cookies.load(name);
        if (value) {
            return value;
        }

        return returnDefault;
    },
    set: (
        name: string,
        data: string | number | object,
        opt: TPath | undefined = { path: '/' }
    ): void => cookies.save(name, data, opt),
    remove: (name: string): void => cookies.remove(name),
};

const getStorage = () => CookiesStorage;

export const getCS = (name: string, returnDefault?: string): TCookieData =>
    getStorage().get(name, returnDefault);

export const setCS = (name: string, value: string | number | object, options?: TPath) =>
    getStorage().set(name, value, options);

export const removeCS = (name: string) => getStorage().remove(name);

export const loadCS = (name: string): TCookieData => {
    try {
        return getCS(name);
    } catch (err) {
        console.log(err);
        return undefined;
    }
};

export const saveCS = (
    name: string,
    value: string | number | object,
    options?: TPath
): void | null => {
    try {
        return setCS(name, value, options);
    } catch (err) {
        console.log(err);
        return null;
    }
};

export const loadCookiePromocode = () => {
    const promo = loadCS(CookiesTypes.CS_PROMOCODE);

    if (promo && typeof promo === 'string') {
        return promo;
    }

    return undefined;
};
export const saveCookiePromocode = (value: string) =>
    saveCS(CookiesTypes.CS_PROMOCODE, value, {
        path: '/',
        expires: toDate(addHours(new Date(), THREE_DAYS)),
    });
export const clearPromocode = () => removeCS(CookiesTypes.CS_PROMOCODE);

export const loadTokenCart = () => loadCS(CookiesTypes.CS_TOKEN_CART);
export const saveTokenCart = (value: string | number | object) =>
    saveCS(CookiesTypes.CS_TOKEN_CART, value);
export const clearTokenCart = () => removeCS(CookiesTypes.CS_TOKEN_CART);
export const saveProductListClick = (value = '') =>
    saveCS('_tsinls', value, {
        path: '/',
        expires: toDate(addMinutes(new Date(), 1)),
    });
