import { isSafari, isChrome, isEDGE } from 'utils/browser';
import { Analytics } from '..';

export type QuickPayLabel = 'Apple Pay' | 'Google Pay' | 'Microsoft Pay' | 'Unknown';

export default function quickPay(self: Analytics) {
    let label: QuickPayLabel;
    const getLabel = () => {
        if (label !== undefined) {
            return label;
        }

        return (label = (() => {
            if (isSafari()) {
                return 'Apple Pay';
            }
            if (isChrome()) {
                return 'Google Pay';
            }
            if (isEDGE()) {
                return 'Microsoft Pay';
            }
            return 'Unknown';
        })());
    };

    const dispatchEvent = (
        action:
            | 'Displayed Button'
            | 'Transaction Started'
            | 'Transaction Successed'
            | 'Transaction Failed'
    ) => {
        const data = {
            action,
            category: 'Quick Payment',
            label: getLabel(),
            nonInteraction: true,
        };

        self.DataLayerEvent(data);

        return self;
    };

    return {
        get label() {
            return getLabel();
        },
        displayButton() {
            return dispatchEvent('Displayed Button');
        },
        transactionStart() {
            return dispatchEvent('Transaction Started');
        },
        transactionSuccessed() {
            return dispatchEvent('Transaction Successed');
        },
        transactionFailed() {
            return dispatchEvent('Transaction Failed');
        },
    };
}
