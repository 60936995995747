export const GTMID = 'GTM-PQN3GGF';
export const isProdBuild = process.env.BUILD_ENV === 'prod';

export const buildEnv: typeof process.env.BUILD_ENV = isProdBuild ? 'prod' : 'dev';

const GoogleTMConfigurations: {
    [key: string]: unknown;
    dev: string;
    prod: string;
} = {
    prod: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=twJL5KtT0BJA3awX4Cwe_g&gtm_preview=env-2&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${GTMID}');`,

    dev: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=GAWhprrbAQZcdp-yDHeHzw&gtm_preview=env-65&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${GTMID}');`,
};

export const GoogleTMConfiguration = GoogleTMConfigurations[buildEnv] || GoogleTMConfigurations.dev;

export const OptimizeTriggerScript =
    'window.dataLayer = window.dataLayer || []; if (MutationObserver) {new MutationObserver(function() {window.dataLayer.push({ event: `optimize.activate` });}).observe(document.body, {subtree: true,attributes: true,characterData: true,childList: true,});}';

export const OptimizeAntiFlickScript = `
(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
(a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
})(window,document.documentElement,'async-hide','dataLayer',4000,
{'${GTMID}':true});
`;

const NoscriptFrameSrc = isProdBuild
    ? `https://www.googletagmanager.com/ns.html?id=${GTMID}&gtm_auth=twJL5KtT0BJA3awX4Cwe_g&gtm_preview=env-2&gtm_cookies_win=x`
    : `https://www.googletagmanager.com/ns.html?id=${GTMID}&gtm_auth=GAWhprrbAQZcdp-yDHeHzw&gtm_preview=env-65&gtm_cookies_win=x`;

export const NoscriptFrame = `
<iframe height="0" width="0" style="display:none;visibility:hidden;" src="${NoscriptFrameSrc}"></iframe>`;
