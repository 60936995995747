/* eslint-disable @typescript-eslint/no-explicit-any */
import { Product, ProductInWishList } from '../Analytics.types';
import { Analytics } from '..';

export type WishListListName = 'Search' | 'Details' | '';

export default function wishList(self: Analytics) {
    return {
        add(listName: WishListListName = '', product: Product) {
            const data = {
                action: 'Added',
                category: ['Wishlist', listName].filter(Boolean).join(' - '),
                label: `SKU = ${product.id}`,
                nonInteraction: true,
            };

            self.DataLayerEvent(data);

            self.fbq('track', 'AddToWishlist', {
                content_ids: product.id,
                content_category: product.category,
                content_name: product.name,
                content_type: 'product',
                product_catalog_id: 333969174115095,
            });
            return self;
        },
        remove(listName: WishListListName = '', product: Product) {
            const data = {
                action: 'Removed',
                category: ['Wishlist', listName].filter(Boolean).join(' - '),
                label: `SKU = ${product.id}`,
                nonInteraction: true,
            };

            self.DataLayerEvent(data);

            self.fbq('track', 'RemoveFromWishlist', {
                content_ids: product.id,
                content_category: product.category,
                content_name: product.name,
                content_type: 'product',
                product_catalog_id: 333969174115095,
            });
            return self;
        },
        viewWishList(payload: ProductInWishList | ProductInWishList[]) {
            const products = Array.isArray(payload) ? payload : [payload];

            if (products.length <= 0) {
                return self;
            }

            self.dataLayer({
                event: 'viewWishList',
                ecommerce: {
                    detail: {
                        products,
                    },
                },
            });

            return self;
        },
    };
}
