import { TBoxShadows } from '../Theme.domain';

export const BoxShadows: TBoxShadows = {
    primary: '0px 16px 28px rgba(39, 174, 96, 0.3), 0px 10px 16px rgba(39, 174, 96, 0.15)',
    secondary: '0px 20px 13px rgb(17 15 15 / 5%), 0px 8.14815px 6.51852px rgb(17 15 15 / 4%)',
    tertiary: '0px 3.89312px 23.3587px rgba(189, 189, 189, 0.23)',
    quaternary:
        '0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff, 18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0)',
    spinner0100:
        '0 -18px 0 0 #DDDDDD, 12.72984px -12.72984px 0 0 #DDDDDD, 18px 0 0 0 #DDDDDD, 12.72984px 12.72984px 0 -5px rgba(196, 196, 196, 0), 0 18px 0 -5px rgba(196, 196, 196, 0), -12.72984px 12.72984px 0 -5px rgba(196, 196, 196, 0), -18px 0 0 -5px rgba(196, 196, 196, 0), -12.72984px -12.72984px 0 -5px rgba(196, 196, 196, 0)',
    spinner12:
        '0 -18px 0 -5px rgba(196, 196, 196, 0), 12.72984px -12.72984px 0 0 #DDDDDD, 18px 0 0 0 #DDDDDD, 12.72984px 12.72984px 0 0 #DDDDDD, 0 18px 0 -5px rgba(196, 196, 196, 0), -12.72984px 12.72984px 0 -5px rgba(196, 196, 196, 0), -18px 0 0 -5px rgba(196, 196, 196, 0), -12.72984px -12.72984px 0 -5px rgba(196, 196, 196, 0)',
    spinner25:
        '0 -18px 0 -5px rgba(196, 196, 196, 0), 12.72984px -12.72984px 0 -5px rgba(196, 196, 196, 0), 18px 0 0 0 #DDDDDD, 12.72984px 12.72984px 0 0 #DDDDDD, 0 18px 0 0 #DDDDDD, -12.72984px 12.72984px 0 -5px rgba(196, 196, 196, 0), -18px 0 0 -5px rgba(196, 196, 196, 0), -12.72984px -12.72984px 0 -5px rgba(196, 196, 196, 0)',
    spinner37:
        '0 -18px 0 -5px rgba(196, 196, 196, 0), 12.72984px -12.72984px 0 -5px rgba(196, 196, 196, 0), 18px 0 0 -5px rgba(196, 196, 196, 0), 12.72984px 12.72984px 0 0 #DDDDDD, 0 18px 0 0 #DDDDDD, -12.72984px 12.72984px 0 0 #DDDDDD, -18px 0 0 -5px rgba(196, 196, 196, 0), -12.72984px -12.72984px 0 -5px rgba(196, 196, 196, 0)',
    spinner50:
        '0 -18px 0 -5px rgba(196, 196, 196, 0), 12.72984px -12.72984px 0 -5px rgba(196, 196, 196, 0), 18px 0 0 -5px rgba(196, 196, 196, 0), 12.72984px 12.72984px 0 -5px rgba(196, 196, 196, 0), 0 18px 0 0 #DDDDDD, -12.72984px 12.72984px 0 0 #DDDDDD, -18px 0 0 0 #DDDDDD, -12.72984px -12.72984px 0 -5px rgba(196, 196, 196, 0)',
    spinner62:
        '0 -18px 0 -5px rgba(196, 196, 196, 0), 12.72984px -12.72984px 0 -5px rgba(196, 196, 196, 0), 18px 0 0 -5px rgba(196, 196, 196, 0), 12.72984px 12.72984px 0 -5px rgba(196, 196, 196, 0), 0 18px 0 -5px rgba(196, 196, 196, 0), -12.72984px 12.72984px 0 0 #DDDDDD, -18px 0 0 0 #DDDDDD, -12.72984px -12.72984px 0 0 #DDDDDD',
    spinner75:
        '0 -18px 0 0 #DDDDDD, 12.72984px -12.72984px 0 -5px rgba(196, 196, 196, 0), 18px 0 0 -5px rgba(196, 196, 196, 0), 12.72984px 12.72984px 0 -5px rgba(196, 196, 196, 0), 0 18px 0 -5px rgba(196, 196, 196, 0), -12.72984px 12.72984px 0 -5px rgba(196, 196, 196, 0), -18px 0 0 0 #DDDDDD, -12.72984px -12.72984px 0 0 #DDDDDD',
    spinner87:
        '0 -18px 0 0 #DDDDDD, 12.72984px -12.72984px 0 0 #DDDDDD, 18px 0 0 -5px rgba(196, 196, 196, 0), 12.72984px 12.72984px 0 -5px rgba(196, 196, 196, 0), 0 18px 0 -5px rgba(196, 196, 196, 0), -12.72984px 12.72984px 0 -5px rgba(196, 196, 196, 0), -18px 0 0 -5px rgba(196, 196, 196, 0), -12.72984px -12.72984px 0 0 #DDDDDD',
};
