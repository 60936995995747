import { IActivity } from '@/entities/Activity/domain/Activity.domain';
import { LocalStorageTypes } from '@/common/service/storage/Storage.domain';
import { getLS, removeLS, setLS } from '@/common/service/storage/LocalStorage/LocalStorage';

const KEY = LocalStorageTypes.LS_CART_ACTIVITIES;

const getOldList = (): IActivity[] => getLS(KEY) || [];

export const getStorageCartActivities = () => getOldList();

export const setStorageCartActivity = (activity: IActivity) => {
    const oldList = getOldList();

    if (!oldList?.some(({ id }) => id === activity.id)) {
        setLS(KEY, [...oldList, activity]);
    }
};

export const delStorageCartActivity = () => {
    return removeLS(KEY);
};

export const removeStorageActivityItem = (id: string) => {
    const oldList = getOldList();
    const newList = oldList.filter((item) => item.id !== id);

    if (newList.length) {
        setLS(KEY, newList);
    } else {
        delStorageCartActivity();
    }
};
