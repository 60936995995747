/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { saveProductListClick } from '@/common/service/storage/CookieStorage/CookieStorage';
import { Product, ProductInList, ProductDetail, ProductCartItem } from '../Analytics.types';
import { CURRENCY } from '../variables';
import { Analytics } from '..';

export default function product(self: Analytics) {
    return {
        click(payload: Product | Product[] | ProductInList | ProductInList[], listName = '') {
            const products = Array.isArray(payload) ? payload : [payload];
            if (products.length <= 0) {
                return self;
            }

            const data = {
                event: 'productClick',
                ecommerce: {
                    click: {
                        actionField: { action: 'click', list: listName },
                        products,
                    },
                },
            };

            self.dataLayer(data);

            const lastItem = products[products.length - 1];

            if (lastItem && lastItem.id && listName) {
                saveProductListClick([lastItem.id, listName].join('|'));
            }

            return self;
        },

        clickCheckAvailability(payload: Product | Product[]) {
            const products = Array.isArray(payload) ? payload : [payload];

            if (products.length <= 0) {
                return self;
            }

            const data = {
                action: 'Clicked Check',
                category: 'Activity Availability',
                label: 'Button',
                nonInteraction: false,
            };

            self.DataLayerEvent(data);

            self.dataLayer({
                event: 'productClickCheckAvailability',
                products,
            });

            return self;
        },

        selectAvailabilityDates(
            payload: Product | Product[],
            { startDate, endDate }: { startDate: string; endDate?: string }
        ) {
            const products = Array.isArray(payload) ? payload : [payload];

            if (products.length <= 0) {
                return self;
            }

            const data = {
                category: 'Activity Availability',
                action: 'Select Dates',
                label: [startDate, endDate].filter(Boolean).join(' ~ '),
                nonInteraction: false,
            };

            self.DataLayerEvent(data);

            self.dataLayer({ event: 'productSelectAvailabilityDates', products });

            return self;
        },

        selectAvailabilityDay(payload: Product | Product[], { dayDate }: { dayDate: string }) {
            const products = Array.isArray(payload) ? payload : [payload];

            if (products.length <= 0) {
                return self;
            }

            const data = {
                category: 'Activity Availability',
                action: 'Select Day',
                label: dayDate,
                nonInteraction: false,
            };

            self.DataLayerEvent(data);

            self.dataLayer({ event: 'productSelectAvailabilityDay', products });

            return self;
        },

        detail(payload: ProductDetail | ProductDetail[]) {
            const products = Array.isArray(payload) ? payload : [payload];

            if (products.length <= 0) {
                return self;
            }

            const data = {
                ecommerce: {
                    detail: {
                        actionField: { action: 'detail' },
                        products,
                    },
                },
                event: 'productDetail',
            };

            self.dataLayer(data);

            products.forEach((product) => {
                self.fbq('track', 'ViewContent', {
                    content_ids: product.id,
                    content_category: product.category,
                    content_name: product.name,
                    content_type: 'product',
                    product_catalog_id: 333969174115095,
                });
            });

            return self;
        },

        addToCart(payload: ProductCartItem | ProductCartItem[]) {
            const products = Array.isArray(payload) ? payload : [payload];

            if (products.length <= 0) {
                return self;
            }

            const data = {
                event: 'addToCart',
                ecommerce: {
                    add: {
                        products,
                    },
                },
            };

            self.dataLayer(data);

            products.forEach((product) => {
                self.fbq('track', 'AddToCart', {
                    value: product.price,
                    currency: CURRENCY,
                    content_name: product.name,
                    content_type: 'product',
                    product_catalog_id: 333969174115095,
                    content_ids: product.id,
                });
            });

            return self;
        },

        removeFromCart(payload: ProductCartItem | ProductCartItem[]) {
            const products = Array.isArray(payload) ? payload : [payload];

            if (products.length <= 0) {
                return self;
            }

            self.dataLayer({
                event: 'removeFromCart',
                ecommerce: {
                    add: {
                        products,
                    },
                },
            });

            products.forEach((product) => {
                self.fbq('track', 'removeFromCart', {
                    value: product.price,
                    currency: CURRENCY,
                    content_name: product.name,
                    content_type: 'product',
                    product_catalog_id: 333969174115095,
                    content_ids: product.id,
                });
            });

            return self;
        },

        viewCart(payload: ProductCartItem | ProductCartItem[]) {
            const products = Array.isArray(payload) ? payload : [payload];

            if (products.length <= 0) {
                return self;
            }

            const data = {
                event: 'productViewCart',
                ecommerce: {
                    detail: {
                        products,
                    },
                },
            };

            self.dataLayer(data);

            return self;
        },
    };
}
