import { CookiesTypes, getCS } from '../../../service/storage';
import {
    sendLiveChatCRS,
    trackCRS,
    trackCheckoutInfoCRS,
    trackPurchaseCRS,
    trackSubscribeCRS,
    trackUpadteCartCRS,
} from './requests';
import { createCRSActivityItem, createCRSAttraction, createCRSCartItems } from './creators';
import { TCRSParams } from '../affiliates';
import { IApiCartItem } from '@/entities/Cart/domain/Cart.domain';
import { IActivity } from '@/entities/Activity/domain/Activity.domain';
import {
    IDestinationCategory,
    IDestinationList,
} from '@/entities/Destination/domain/Destination.domain';

export enum EVENT {
    attractions = 'attractions',
    productDetail = 'productDetail',
    wishListAdded = 'wishListAdded',
    wishListRemoved = 'wishListRemoved',
    checkoutContacts = 'checkoutContacts',
    checkoutPayment = 'checkoutPayment',
    pageView = 'pageView',
    promoCodeRequest = 'promoCodeRequest',
    subscribed = 'subscribed',
    purchase = 'purchase',
    updateCart = 'updateCart',
}

export const getWpage = () =>
    (typeof window !== 'undefined' && window.location.pathname + window.location.search) || '';

export const trackUpdateCart = async (items: IApiCartItem[]) => {
    const data = { cartItems: createCRSCartItems(items) };

    return trackUpadteCartCRS(EVENT.updateCart, data);
};

export const trackAttractions = async (
    destination: IDestinationList,
    category?: IDestinationCategory | null
) => {
    const data = createCRSAttraction(destination, category);

    return trackCRS(EVENT.attractions, data);
};

export const trackProductDetail = async (
    activity: IActivity,
    destination: IDestinationList,
    category?: IDestinationCategory | null
) => {
    const data = createCRSActivityItem(activity, destination, category);

    return trackCRS(EVENT.productDetail, data);
};

export const trackWishListAdded = async (activityId: number | string) => {
    return trackCRS(EVENT.wishListAdded, { activityId: +activityId });
};

export const trackWishListRemoved = async (activityId: number | string) => {
    return trackCRS(EVENT.wishListRemoved, { activityId: +activityId });
};

export const trackPurchase = async (
    grossTotal: number,
    promoDetails: { name: string; code: string },
    items: IApiCartItem[],
    orderId: string
) => {
    const crsParams = getCS(CookiesTypes.CS_CRS_UTM) as TCRSParams | undefined;
    const campaignId = (crsParams?.utm_campaign || crsParams?.campaign)?.replace('campaign_', '');

    const dataReq = {
        total: grossTotal,
        orderId,
        promoCode: promoDetails.code.toLowerCase(),
        promoName: promoDetails.name,
        ...(campaignId && { campaignId }),
        cartItems: createCRSCartItems(items),
    };

    //const uniqIds = [...new Set(Object.values(items).map(({ id }) => id))].join();

    //checkAndTrackCRSParams(uniqIds, crsParams);

    return trackPurchaseCRS(EVENT.purchase, dataReq);
};

export const trackCheckout = async (
    stage: EVENT.checkoutContacts | EVENT.checkoutPayment,
    cartItems: IApiCartItem[]
) => {
    const data = { cartItems: createCRSCartItems(cartItems) };

    if (stage === EVENT.checkoutPayment) {
        return trackCheckoutInfoCRS(stage, data);
    }

    return trackCRS(stage, data);
};

export const trackPageView = async (payload?: { path?: string; title?: string }) => {
    const title = window.document?.title;
    const path = window.location?.pathname;
    const data = {
        path: payload?.path || path,
        title: payload?.title || title,
    };

    return trackCRS(EVENT.pageView, data);
};

export const trackPromoCodeRequest = async (payload: {
    success: boolean;
    name: string;
    code: string;
}) => {
    const data = {
        path: getWpage(),
        promoCode: payload.code.toLowerCase(),
        promoName: payload.name,
        success: payload.success,
    };

    return trackCRS(EVENT.promoCodeRequest, data);
};

export const trackSubscribed = (payload: { email: string }) => {
    return trackSubscribeCRS(EVENT.subscribed, payload.email);
};

export const trackLiveChat = (attributesEmail: string, firstName: string) => {
    return sendLiveChatCRS(attributesEmail, firstName);
};
