import { FlattenSimpleInterpolation } from 'styled-components';
import { TColorLevel } from './Palette';

export enum CustomThemeNames {
    light = 'light',
    dark = 'dark',
}

export type TThemeName = CustomThemeNames.light | CustomThemeNames.dark;

export type TMetrics = {
    none?: string;
    tiny: string;
    small: string;
    designer?: string;
    medium: string;
    normal: string;
    large: string;
    xlarge: string;
    xxlarge?: string;
    huge: string;
    micro?: string;
    giant?: string;
    half?: string;
    button?: string;
};

export type TColors = {
    primary: string;
    secondary: string;
    background: string;
    mint: string;
    ash: string;
    extra: {
        arrow: string;
        arrowHover: string;
        arrowFill: string;
    };
    shades: {
        green1: string;
        green2: string;
        gray1: string;
        gray2: string;
        gray3: string;
        gray4: string;
        hoverLight: string;
        hoverDark: string;
    };
    typography: {
        gray1: string;
        gray2: string;
        white: string;
    };
    state: {
        info: string;
        success: string;
        warning: string;
        error: string;
    };
    white: string;
    black: string;
    gray: string;
    grays: TColorLevel;
    dark: string;
    darks: TColorLevel;
    blue: string;
    blues: TColorLevel;
    green: string;
    greens: TColorLevel;
    gradient: string;
    gradients: {
        light: string;
        dark: string;
    };
    transparent: string;
};

export type TFilter = {
    brightness: string;
};

export type TBoxShadows = {
    primary: string;
    secondary: string;
    tertiary: string;
    quaternary: string;
    spinner0100: string;
    spinner12: string;
    spinner25: string;
    spinner37: string;
    spinner50: string;
    spinner62: string;
    spinner75: string;
    spinner87: string;
};

export type TKeyFrames = {
    background0: string;
    background100: string;
    bgRotation0: string;
    bgRotation100: string;
};

export type TAnimation = {
    animationLinear4: string;
};

export interface TTheme {
    name: TThemeName;
    colors: TColors;
    boxShadows: TBoxShadows;
    keyframes: TKeyFrames;
    animation: TAnimation;
    presets: TPresets;
}

export type TPresets = {
    icon: FlattenSimpleInterpolation;
    chips: FlattenSimpleInterpolation;
    button: FlattenSimpleInterpolation;
    spinner: FlattenSimpleInterpolation;
    loader: FlattenSimpleInterpolation;
    socialIcon: FlattenSimpleInterpolation;
};
