import { CURRENCY } from '../variables';
import { ProductCartItem, Purchase } from '../Analytics.types';
import { Analytics } from '..';

export default function checkout(self: Analytics) {
    return {
        step({
            products,
            step,
            option = '',
        }: {
            products: ProductCartItem[];
            step: number;
            option: string;
        }) {
            if (!step || !Array.isArray(products) || products.length <= 0) {
                return self;
            }

            const data = {
                event: 'checkout',
                ecommerce: {
                    checkout: {
                        actionField: {
                            step,
                            option,
                        },
                        products,
                    },
                },
            };

            self.dataLayer(data);

            if (step === 1) {
                self.fbq('track', 'InitiateCheckout');
            } else if (step === 2) {
                self.fbq('track', 'AddPaymentInfo');
            }

            return self;
        },
        transactionComplete({
            products,
            purchase,
        }: {
            products: ProductCartItem[];
            purchase: Purchase;
        }) {
            if (!purchase || !Array.isArray(products) || products.length <= 0) {
                return self;
            }

            const data = {
                user_type: 'traveler',
                event: 'transactionComplete',
                ecommerce: {
                    purchase: {
                        actionField: purchase,
                        products,
                    },
                },
            };

            self.dataLayer(data);

            self.fbq('track', 'Purchase', {
                content_type: 'product',
                contents: products.map((product) => ({
                    id: product.id,
                    quantity: product.quantity,
                })),
                value: purchase.comission,
                currency: CURRENCY,
            });

            return self;
        },
    };
}
