import { SessionStorageType } from '../Storage.domain';

// Session Storage.
const SessionStorage = {
    get: <T>(name: string, returnDefault?: T): T | undefined => {
        if (!name?.length) {
            throw new Error('Invalid key.');
        }

        const value = window.sessionStorage.getItem(name);

        if (value && value !== '[]') {
            const parsed = JSON.parse(value);
            if (parsed) {
                return parsed;
            }
        }

        return returnDefault;
    },
    set: (name: string, data?: string | number | unknown): void | boolean => {
        if (name.length <= 0) {
            throw new Error('Invalid key.');
        }

        if (!data) {
            return false;
        }

        window.sessionStorage.setItem(name, JSON.stringify(data));

        return true;
    },
    remove: (name: string) => window.sessionStorage.setItem(name, ''),
};

export const updateHistory = (path: string): void => {
    const currentHistory: string | string[] = getSessionStorage(SessionStorageType.HISTORY) || [];

    if (typeof currentHistory !== 'string') {
        currentHistory.unshift(path);
    }

    setSessionStorage(SessionStorageType.HISTORY, JSON.stringify(currentHistory));
};

// !TODO: This must go in the Cart Utils file.
/* export const addCartActivities = (activity: string) => {
    const currentActivity = getSessionStorage(SessionStorageType.CART_ACTIVITIES) || [];
    const newActivity = uniqBy(currentActivity.concat(activity), 'id');

    setSessionStorage(SessionStorageType.CART_ACTIVITIES, JSON.stringify(newActivity));
}; */

const getStorage = () => {
    if (typeof window !== 'undefined' && window.sessionStorage) {
        return SessionStorage;
    }

    return undefined;
};

export const getSessionStorage = <T>(name: string, options?: T): T | undefined =>
    getStorage()?.get<T>(name, options);

export const setSessionStorage = (name: string, data?: string | number | unknown) =>
    getStorage()?.set(name, data);

export const removeSessionStorage = (name: string) => {
    getStorage()?.remove(name);
};

export const getWithExpiry = <T>(key: string): T | null => {
    const itemStr = getSessionStorage<{ expiry: number; value: T }>(key);

    if (!itemStr) {
        return null;
    }

    const item = itemStr;
    const now = new Date();

    if (now.getTime() > item.expiry) {
        setSessionStorage(key, '');
        return null;
    }
    return item.value;
};

export const setWithExpiry = (key: string, value: object, ttl: number) => {
    const now = new Date();

    const item = {
        value,
        expiry: now.getTime() + ttl,
    };

    setSessionStorage(key, item);
};
