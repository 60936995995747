import { isProd } from '@/common/app/constants/envs';
import {
    IAddPaymentEvent,
    IBaseEvent,
    IBeginCheckoutEvent,
    ICartEvent,
    IEventsData,
    IItemEvent,
    IPromotionEvent,
    IPurchaseEvent,
    ISearchEvent,
    ISortingEvent,
} from './interfaces';

/* general */
declare global {
    interface Window {
        dataLayer: {
            push: (payload: object) => void;
            length: number;
        };
    }
}

export const pushEvent = (data: IEventsData[keyof IEventsData]): void => {
    try {
        window.dataLayer.push({ ecommerce_temp: null }); // clear prev data
        window.dataLayer.push(data); // push data

        if (!isProd) {
            console.log('DataLayer GA4', data); // logging for stage & dev
        }
    } catch (err) {
        throw new Error(`DataLayer - GA4 event is error.`);
    }
};

/* On show impression */
export const trackViewItemList = async (data: IItemEvent) => {
    try {
        if (!data.items.length) return;

        pushEvent({
            event: 'view_item_list_temp',
            ecommerce_temp: {
                ...data,
                item_list_name: data.items[0].item_list_name,
                item_list_id: data.items[0].item_list_id,
            },
        });
    } catch (error) {
        console.log(error);
    }
};
/* On click activity */
export const trackSelectItem = async (data: IItemEvent) => {
    try {
        pushEvent({
            event: 'select_item_temp',
            ecommerce_temp: data,
        });
    } catch (error) {
        console.log(error);
    }
};
/* On show details page */
export const trackViewItem = async (data: IBaseEvent) => {
    try {
        pushEvent({
            event: 'view_item_temp',
            ecommerce_temp: data,
        });
    } catch (error) {
        console.log(error);
    }
};

/* On show featured activities list */
export const trackViewPromotion = async (data: IPromotionEvent) => {
    try {
        if (!data.items.length) return;

        pushEvent({
            event: 'view_promotion_temp',
            ecommerce_temp: {
                ...data,
                promotion_name: data.promotion_name || data.items[0].item_list_name,
                creative_slot: data.items[0].item_category3,
            },
        });
    } catch (error) {
        console.log(error);
    }
};
/* On click featured activities list */
export const trackSelectPromotion = async (data: IPromotionEvent) => {
    try {
        pushEvent({
            event: 'select_promotion_temp',
            ecommerce_temp: data,
        });
    } catch (error) {
        console.log(error);
    }
};

/* Click add to cart button */
export const trackAddToCart = async (data: ICartEvent) => {
    try {
        pushEvent({
            event: 'add_to_cart_temp',
            ecommerce_temp: data,
        });
    } catch (error) {
        console.log(error);
    }
};
/* When show cart */
export const trackViewCart = async (data: ICartEvent) => {
    try {
        if (!data.items.length) return;

        pushEvent({
            event: 'view_cart_temp',
            ecommerce_temp: data,
        });
    } catch (error) {
        console.log(error);
    }
};
/* Click remove from cart button on the /cart page */
export const trackRemoveFromCart = async (data: ICartEvent) => {
    try {
        pushEvent({
            event: 'remove_from_cart_temp',
            ecommerce_temp: data,
        });
    } catch (error) {
        console.log(error);
    }
};

/* Click checkout button on /cart page */
export const trackBeginCheckout = async (data: IBeginCheckoutEvent) => {
    try {
        pushEvent({
            event: 'begin_checkout_temp',
            ecommerce_temp: {
                ...data,
                ...(data.items[0]?.coupon ? { coupon: data.items[0]?.coupon } : {}),
            },
        });
    } catch (error) {
        console.log(error);
    }
};

/* When choose a payment method */
export const trackPaymentInfo = async (data: IAddPaymentEvent) => {
    try {
        pushEvent({
            event: 'add_payment_info_temp',
            ecommerce_temp: {
                ...data,
                ...(data.items[0]?.coupon ? { coupon: data.items[0]?.coupon } : {}),
            },
        });
    } catch (error) {
        console.log(error);
    }
};

/* On payment succesful */
export const trackPurchaseGA4 = async (data: IPurchaseEvent) => {
    try {
        pushEvent({
            event: 'purchase_temp',
            ecommerce_temp: data,
        });
    } catch (error) {
        console.log(error);
    }
};

/* Click add to wish list button */
export const trackAddToWishList = async (data: IBaseEvent) => {
    try {
        pushEvent({
            event: 'add_to_wishlist_temp',
            ecommerce_temp: data,
        });
    } catch (error) {
        console.log(error);
    }
};

/* On click search button */
export const trackSearch = async (data: ISearchEvent) => {
    try {
        pushEvent({
            event: 'search_temp',
            ecommerce_temp: data,
        });
    } catch (error) {
        console.log(error);
    }
};

/* Click on sorting buttons or dropdown */
export const trackSortingSelected = async (data: ISortingEvent) => {
    try {
        pushEvent({
            event: 'select_search_results_order',
            ecommerce_temp: data,
        });
    } catch (error) {
        console.log(error);
    }
};

/* Click on arrows  or swipe on the images gallery */
export const trackImageGallery = async () => {
    try {
        pushEvent({
            event: 'images_gallery',
        });
    } catch (error) {
        console.log(error);
    }
};

export const trackChatOpenClick = async () => {
    try {
        pushEvent({
            event: 'on_chat_open_click',
        });
    } catch (error) {
        console.log(error);
    }
};
/* Chat window was opened */
export const trackChatWindowOpened = async () => {
    try {
        pushEvent({
            event: 'on_chat_window_opened',
        });
    } catch (error) {
        console.log(error);
    }
};
/* Chat was started */
export const trackChatStarted = async () => {
    try {
        pushEvent({
            event: 'on_chat_started',
        });
    } catch (error) {
        console.log(error);
    }
};
/* Chat was finished */
export const trackChatEnded = async () => {
    try {
        pushEvent({
            event: 'on_chat_ended',
        });
    } catch (error) {
        console.log(error);
    }
};
