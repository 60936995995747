export const LAST_DESTINATION = '_ts__last_destination';
export const CLICK_POSITION = '_ts__click_position';
export const HISTORY = '_ts__history';
export const CART_ACTIVITIES = '_ts__cart_activities';
export const SEARCH_LIST = '_ts__search_list';

const SessionStorage = {
    get: (name, returnDefault = null) => {
        if (typeof name !== 'string' || name.length <= 0) {
            throw new Error('Invalid key.');
        }

        const value = window.sessionStorage.getItem(name);

        if (value && typeof value === 'string' && value !== '[]') {
            const parsed = JSON.parse(value);
            if (parsed) {
                return parsed;
            }
        }

        return returnDefault;
    },
    set: (name, data = null) => {
        if (typeof name !== 'string' || name.length <= 0) {
            throw new Error('Invalid key.');
        }

        if (data === null) {
            return false;
        }

        window.sessionStorage.setItem(name, JSON.stringify(data));

        return true;
    },
};

export const updateHistory = (path) => {
    const currentHistory = getSessionStorage(HISTORY) || [];
    currentHistory.unshift(path);

    setSessionStorage(HISTORY, currentHistory);
}

// export const addCartActivities = (activity) => {
//     const currentActivity = getSessionStorage(CART_ACTIVITIES) || [];
//     const newActivity = uniqBy(
//         currentActivity.concat(activity),
//         'id'
//     );

//     setSessionStorage(CART_ACTIVITIES, newActivity);
// }


export const setWithExpiry = (key, value, ttl) => {
    const now = new Date()

    const item = {
        value,
        expiry: now.getTime() + ttl,
    }

    setSessionStorage(key, item)
}

export const getWithExpiry = (key) => {
    const itemStr = getSessionStorage(key)

    if (!itemStr) {
        return null
    }

    const item = itemStr;
    const now = new Date()

    if (now.getTime() > item.expiry) {
        setSessionStorage(key, '')
        return null
    }
    return item.value
}

const getStorage = () => {
    if (window.sessionStorage !== undefined) {
        return SessionStorage;
    }

    throw new Error('Storage not defined');
};

export const getSessionStorage = (...args) => getStorage().get(...args);
export const setSessionStorage = (...args) => getStorage().set(...args);
