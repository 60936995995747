/* eslint-disable @typescript-eslint/no-explicit-any */
import { format } from 'date-fns';
import { SEARCH_FORMAT_DATE } from '../../../app/constants/timeConstants';
import { Analytics } from '..';

export default function navigation(self: Analytics) {
    return {
        clickedLogotype() {
            const data = {
                action: 'Clicked Logotype',
                category: 'Navigation',
                nonInteraction: false,
            };

            self.DataLayerEvent(data);

            return self;
        },

        clickedCartButton() {
            const data = {
                action: 'Clicked Cart',
                category: 'Navigation',
                label: 'Button',
                nonInteraction: false,
            };
            self.DataLayerEvent(data);

            return self;
        },

        clickedWishButton() {
            const data = {
                action: 'Clicked Wish',
                category: 'Navigation',
                label: 'Button',
                nonInteraction: false,
            };
            self.DataLayerEvent(data);

            return self;
        },

        clickedSearchButton({
            destination,
            category,
        }: {
            destination?: { name: string };
            category?: { name: string };
            info?: any;
        }) {
            const data = {
                action: 'Clicked Search',
                category: 'Navigation',
                label: 'Button',
                nonInteraction: false,
            };
            self.DataLayerEvent(data);

            if (!destination) {
                return self;
            }

            self.fbq('track', 'Search', {
                search_string: [destination.name, category && category.name]
                    .filter(Boolean)
                    .join(' / '),
            });

            return self;
        },

        selectedSearch({
            destination,
            category,
        }: {
            destination?: { name: string };
            category?: { name: string };
            info?: any;
        }) {
            if (!destination) {
                return self;
            }
            const data = {
                action: 'Selected Search',
                category: 'Navigation',
                label: [destination.name, category && category.name].filter(Boolean).join(' / '),
                nonInteraction: false,
            };
            self.DataLayerEvent(data);

            return self;
        },

        selectedSearchDates({
            startDate,
            endDate,
        }: {
            startDate?: Date;
            endDate?: Date;
            info?: any;
        }) {
            if (!startDate) {
                return self;
            }

            const label = [startDate, endDate]
                .filter(Boolean)
                .map((date) => format(date as Date, SEARCH_FORMAT_DATE))
                .join(' ~ ');

            const data = {
                action: 'Selected Search Dates',
                category: 'Navigation',
                label,
                nonInteraction: false,
            };

            self.DataLayerEvent(data);

            return self;
        },
    };
}
