import { Impression } from '../Analytics.types';
import { Analytics } from '..';

export default function impression(self: Analytics) {
    return {
        dispatch(impression: Impression | Impression[]) {
            const products = Array.isArray(impression) ? impression : [impression];

            if (products.length <= 0) {
                return self;
            }

            const data = {
                ecommerce: {
                    impressions: products,
                },
                event: 'impressions',
            };

            self.dataLayer(data);

            return self;
        },
    };
}
