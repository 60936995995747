import {
    IAvailability,
    IAvailabilityMonth,
    TAvailabilityFull,
    TAvailabilityFullParams,
    TAvailabilityMonthParams,
    TAvailabilityParams,
} from './Availability.domain';
import instanse from '@/common/service/api/Axios';
import { ApiUrls } from '@/common/domain/Endpoints.domain';

export const fetchAvailabilityShortByIds = (
    params: TAvailabilityParams
): Promise<IAvailability[]> => {
    return instanse
        .get<IAvailability[]>(ApiUrls.activity.availability.short, { params })
        .then(({ data }) => data);
};

export const fetchAvailabilityShortByIdsForLP = async (
    params: TAvailabilityParams
): Promise<IAvailability[]> => {
    try {
        const { data } = await instanse.get<IAvailability[]>(ApiUrls.activity.availability.short, {
            params,
        });
        return data;
    } catch (error) {
        return [];
    }
};

export const fetchAvailabilityFullByIds = (
    params: TAvailabilityFullParams
): Promise<TAvailabilityFull> => {
    return instanse
        .get<TAvailabilityFull>(ApiUrls.activity.availability.root, { params })
        .then(({ data }) => data);
};

export const fetchAvailabilityByMonth = (
    params: TAvailabilityMonthParams
): Promise<IAvailabilityMonth> => {
    return instanse
        .get<IAvailabilityMonth>(ApiUrls.activity.availability.month, { params })
        .then(({ data }) => data);
};
