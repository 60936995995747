import { createContext, memo, useCallback, useContext, useMemo, useState } from 'react';
import { getLS, LocalStorageTypes, setLS } from '@/common/service/storage';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { LightTheme } from 'src/styles/Themes/LightTheme/LightTheme';
import { DarkTheme } from 'src/styles/Themes/DarkTheme/DarkTheme';
import { CustomThemeNames, TTheme, TThemeName } from '@/styles/Themes/Theme.domain';
import { changeStyleVariables, styleVariables } from '@/styles/Themes/ThemeScript';

type Props = {
    children: JSX.Element;
};

const ThemeContext = createContext<{
    theme: TTheme;
    onChangeTheme?: (newTheme: TThemeName) => void;
    toggleTheme?: () => void;
}>({
    theme: LightTheme,
});

const DThemes = {
    [CustomThemeNames.light]: LightTheme,
    [CustomThemeNames.dark]: DarkTheme,
} as const;

const CustomThemeProvider = ({ children }: Props) => {
    const clientTheme = getLS(LocalStorageTypes.LS_THEME_MODE);

    const isDark = clientTheme === CustomThemeNames.dark;

    const [theme, setTheme] = useState<TTheme>(isDark ? DarkTheme : LightTheme);
    const [currentTheme, setCurrentTheme] = useState<TThemeName>(
        isDark ? CustomThemeNames.dark : CustomThemeNames.light
    );

    const toggleTheme = useCallback(() => {
        const newTheme =
            currentTheme === CustomThemeNames.light
                ? CustomThemeNames.dark
                : CustomThemeNames.light;
        if (newTheme === currentTheme) return;

        changeStyleVariables(styleVariables[newTheme]);
        setCurrentTheme(newTheme);
        setTheme(DThemes[newTheme]);
        setLS(LocalStorageTypes.LS_THEME_MODE, newTheme);
    }, [currentTheme]);

    const contextProviderValue = useMemo(() => ({ theme, toggleTheme }), [theme, toggleTheme]);

    return (
        <>
            <ThemeContext.Provider value={contextProviderValue}>
                <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
            </ThemeContext.Provider>
        </>
    );
};

export const ThemeProvider = memo(CustomThemeProvider);

export const useThemeContext = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useThemeContext must be used within a ThemeProvider');
    }
    return context;
};

export default CustomThemeProvider;
