import { CustomThemeNames, TTheme } from '../Theme.domain';
import { colors } from './Colors';
import { BoxShadows } from './Shadows';
import { keyframes } from '../KeyFrames';
import { animation } from '../Animation';
import { Presets } from './Presets';

export const LightTheme: TTheme = {
    name: CustomThemeNames.light,
    colors: colors,
    boxShadows: BoxShadows,
    keyframes: keyframes,
    animation: animation,
    presets: Presets,
};
