/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable camelcase */
/* eslint-disable  */
import { trackPageView } from '../../app/utils/iterable';
import { ANALYTICS_IS_DISABLED } from './variables';
import * as creators from './creators';
import {
    DataLayerEvent,
    Impression,
    Product,
    ProductDetail,
    ProductInList,
    ProductCartItem,
    Purchase,
} from './Analytics.types';

import wishListEvents, { WishListListName } from './events/wishList';
import quickPayEvents, { QuickPayLabel } from './events/quickPay';
import accountEvents, { AccountData, SubscribeProp } from './events/account';
import impressionEvents from './events/impression';
import productEvents from './events/product';
import checkoutEvents from './events/checkout';
import navigationEvents from './events/navigation';
import { isProd } from '@/common/app/constants/envs';

const ENABLE_DEBUG = !isProd || Boolean(isProd && process.env.ROBOTS_OFF === '1');

function pickDefined<T extends object>(obj: T) {
    return Object.keys(obj).reduce<T>((acc, curr) => {
        if (typeof obj[curr as keyof T] === 'undefined') {
            return acc;
        }

        return { ...acc, [curr]: obj[curr as keyof T] };
    }, {} as T);
}

export class Analytics {
    debug = ENABLE_DEBUG;
    testMode = false;
    disDlvWarn = false;
    disFbqWarn = false;

    // Events
    wishList: {
        add(
            listName: WishListListName | undefined,
            product: Product | undefined,
            activity: any
        ): Analytics;
        remove(listName: WishListListName, product: Product, activity: any): Analytics;
    };

    quickPay: {
        readonly label: QuickPayLabel;
        displayButton(): Analytics;
        transactionStart(): Analytics;
        transactionSuccessed(): Analytics;
        transactionFailed(): Analytics;
    };

    account: {
        userId(userId: string | number): Analytics;
        contactInfo(data: AccountData): Analytics;
        subscribe(data: SubscribeProp): Analytics;
    };

    impression: { dispatch(impression: Impression | Impression[]): Analytics };

    product: {
        click(
            payload: Product | ProductInList | Product[] | ProductInList[],
            listName?: string
        ): Analytics;
        clickCheckAvailability(payload: Product | Product[]): Analytics;
        selectAvailabilityDates(
            payload: Product | Product[],
            { startDate, endDate }: { startDate: string; endDate?: string }
        ): Analytics;
        selectAvailabilityDay(
            payload: Product | Product[],
            { dayDate }: { dayDate: string }
        ): Analytics;
        detail(payload: ProductDetail | ProductDetail[], listName: string, dataSet: any): Analytics;
        addToCart(payload: ProductCartItem | ProductCartItem[]): Analytics;
        viewCart(payload: ProductCartItem | ProductCartItem[], activity: any): Analytics;
        removeFromCart: (payload: ProductCartItem | ProductCartItem[]) => void;
    };

    checkout: {
        step({
            products,
            step,
            option,
        }: {
            products: Product[];
            step: number;
            option: string;
        }): Analytics;

        transactionComplete({
            products,
            purchase,
        }: {
            products: ProductCartItem[];
            purchase: Purchase;
        }): Analytics;
    };

    navigation: {
        clickedLogotype(info: any): Analytics;
        clickedCartButton(info: any): Analytics;
        clickedWishButton(info: any): Analytics;
        clickedSearchButton({
            destination,
            category,
            info,
        }: {
            destination?: { name: string };
            category?: { name: string };
            info?: any;
        }): Analytics;
        selectedSearch({
            destination,
            category,
            info,
        }: {
            destination?: { name: string };
            category?: { name: string };
            info?: any;
        }): Analytics;
        selectedSearchDates({
            startDate,
            endDate,
            info,
        }: {
            startDate?: Date;
            endDate?: Date;
            info?: any;
        }): Analytics;
    };

    constructor() {
        this.wishList = wishListEvents(this);
        this.quickPay = quickPayEvents(this);
        this.account = accountEvents(this);
        this.impression = impressionEvents(this);
        this.product = productEvents(this);
        this.checkout = checkoutEvents(this);
        this.navigation = navigationEvents(this);
    }

    enableTestMode(b: boolean) {
        this.testMode = b;
        return this;
    }

    enableDebug(b: boolean) {
        this.debug = b;
        return this;
    }

    get dataLayer(): Function {
        if (this.testMode) {
            return console.log;
        }

        const dlv: string = ANALYTICS_IS_DISABLED ? 'dataLayerTM' : 'dataLayer';

        try {
            // @ts-ignore
            if (window && window[dlv]) {
                return this.debug
                    ? (...args: any) => {
                          console.log(dlv, ...args);
                          // @ts-ignore
                          return window[dlv].push(...args);
                      }
                    : // @ts-ignore
                      window[dlv].push;
            }
            throw new Error(`DataLayer (${dlv}) is undefined.`);
        } catch (err) {
            if (!this.disDlvWarn) {
                console.warn(err);
            }
            this.disDlvWarn = true;
            return console.log;
        }
    }

    get fbq(): Function {
        if (this.testMode) {
            return console.log;
        }

        const fbq = ANALYTICS_IS_DISABLED ? 'fbqTM' : 'fbq';

        try {
            // @ts-ignore
            if (window && window[fbq]) {
                return this.debug
                    ? (...args: any) => {
                          console.log(fbq, ...args);
                          // @ts-ignore
                          return window[fbq](...args);
                      }
                    : // @ts-ignore
                      window[fbq];
            }
            throw new Error(`FBQ (${fbq}) is undefined.`);
        } catch (err) {
            if (!this.disFbqWarn) {
                console.warn(err);
            }

            this.disFbqWarn = true;
            return console.log;
        }
    }

    get creators() {
        return creators;
    }

    wrapAvoidError(handler: Function) {
        if (typeof handler !== 'function') {
            return false;
        }

        try {
            return handler();
        } catch (err) {
            console.log(err);
            return false;
        }
    }

    pageview(page = '/', title = '') {
        this.dataLayer({
            event: 'pageview',
            page: { path: page, title },
        });

        trackPageView({
            path: page,
            title,
        });

        return true;
    }

    onClickStickyCheckAvailabilityButton() {
        const data = {
            category: 'Check Availability Widget',
            action: 'clicked',
            label: 'button',
            nonInteraction: false,
        };

        this.DataLayerEvent(data);
    }
    openNavigation() {
        const data = {
            category: 'Menu',
            action: 'open',
            label: 'button',
            nonInteraction: false,
        };
        this.DataLayerEvent(data);
    }
    closeNavigation() {
        const data = {
            category: 'Menu',
            action: 'close',
            label: 'button',
            nonInteraction: false,
        };
        this.DataLayerEvent(data);
    }
    openTalkToUsSideMenu() {
        const data = {
            category: 'Talk to Us',
            action: 'click',
            label: 'side menu link',
            nonInteraction: false,
        };
        this.DataLayerEvent(data);
    }
    openTalkToUsCartHeader() {
        const data = {
            category: 'Talk to Us',
            action: 'click',
            label: 'cart header link',
            nonInteraction: false,
        };
        this.DataLayerEvent(data);
    }
    openTalkToUsTripPageLink() {
        const data = {
            category: 'Talk to Us',
            action: 'click',
            label: 'trip page link',
            nonInteraction: false,
        };
        this.DataLayerEvent(data);
    }
    callMe() {
        const data = {
            category: 'Talk to Us - Call Me',
            action: 'submit',
            label: 'button',
            nonInteraction: false,
        };
        this.DataLayerEvent(data);
    }
    openChat() {
        const data = {
            category: 'Open Chat',
            action: 'click',
            label: 'button',
            nonInteraction: false,
        };
        this.DataLayerEvent(data);
    }
    subscribe() {
        const data = {
            category: 'Subscribe',
            action: 'submit',
            label: 'button',
            nonInteraction: false,
        };
        this.DataLayerEvent(data);
    }
    continueShopping() {
        const data = {
            category: 'Continue Shopping',
            action: 'click',
            label: 'button',
            nonInteraction: false,
        };
        this.DataLayerEvent(data);
    }
    cryptoWallet() {
        const data = {
            category: 'Connect Crypto Wallet',
            action: 'click',
            label: 'button',
            nonInteraction: false,
        };
        this.DataLayerEvent(data);
    }
    chatWindowOpened() {
        const data = {
            category: 'LC',
            action: 'on_chat_window_opened',
            event: 'chatWindowOpened',
        };
        this.DataLayerEvent(data);
    }
    chatStarted() {
        const data = {
            category: 'LC',
            action: 'on_chat_started',
            event: 'chatStarted',
        };
        this.DataLayerEvent(data);
    }
    chatEnded() {
        const data = {
            category: 'LC',
            action: 'on_chat_ended',
            event: 'chatEnded',
        };
        this.DataLayerEvent(data);
    }
    OneTapEvent(select_by: string) {
        if (!select_by) {
            return;
        }
        const action = select_by === 'auto' ? 'auto' : 'clicked';
        const label =
            select_by === 'auto' || select_by === 'user' ? 'returning-one-tap' : 'one-tap';

        const data = {
            category: 'Authorization',
            action,
            label,
            nonInteraction: false,
        };

        this.DataLayerEvent(data);
    }

    wpageview() {
        const v = window.document.title;
        const z = this.wpage;
        this.dataLayer({
            event: 'pageview',
            page: { path: z, title: v },
            env: process.env.NODE_ENV,
            buildenv: process.env.BUILD_ENV,
        });

        trackPageView({
            path: z,
            title: v,
        });

        if (v === 'TripShock! ...') {
            console.warn(
                '[Analytics]',
                'Everything is very bad! We spotted the incomplete load at the `wpageview` event',
                z
            );
        }

        return this;
    }

    get wpage() {
        return window.location.pathname + window.location.search;
    }

    /**
     * Additional Events
     *  - category
     *  - action
     *  - label
     */

    DataLayerEvent(payload: DataLayerEvent) {
        const defined = pickDefined<DataLayerEvent>(payload);
        const event: DataLayerEvent & { event: 'custom' } = {
            event: 'custom',
            ...(defined as DataLayerEvent),
        };

        this.dataLayer(event);

        return this;
    }

    event(payload: DataLayerEvent) {
        return this.DataLayerEvent(payload);
    }
}

const AnalyticsInstance = new Analytics();

export { AnalyticsInstance };
