export type TColorLevel = {
    white: string;
    lighter: string;
    light: string;
    normal: string;
    dark: string;
    darker: string;
    strong: string;
    black: string;
    error: string;
};

const Palette = {
    mint: {
        white: '#cfeee2',
        lighter: '#bfe9d9',
        light: '#aee3cf',
        normal: '#9dddc6',
        dark: '#D1FFD5',
        darker: '#81b4a2',
        strong: '#668d7f',
        black: '#4d685e',
    },
    ash: {
        normal: '#FAFAFA',
        darker: '#F2F2F2',
    },
    white: {
        normal: '#ffffff',
    },
    black: {
        normal: '#000000',
    },
    gray: {
        white: '#DDDDDD',
        lighter: '#D0D0D0',
        light: '#C4C4C4',
        normal: '#838282',
        dark: '#6c6b6b',
        darker: '#565555',
        strong: '#414040',
        black: '#333333',
        gray1: '#333333',
        gray2: '#6C6B6B',
        gray3: '#C4C4C4',
        gray4: '#DDDDDD',
    },
    dark: {
        white: '#818c99',
        lighter: '#697786',
        light: '#57626e',
        normal: '#464e58',
        dark: '#44566a',
        darker: '#364555',
        strong: '#2c3944',
        black: '#202020',
        dark1: '#697786',
        dark2: '#364555',
        dark3: '#293847',
        dark4: '#202020',
        dark5: '#1D2A37',
    },
    blue: {
        white: '#a7ddec',
        lighter: '#86d2e6',
        light: '#5fc6df',
        normal: '#222F3C',
        dark: '#192833',
        darker: '#16859e',
        strong: '#1c697c',
        black: '#1c4e5c',
        lightBlue: '#e0fafe',
    },
    green: {
        white: '#D1F6F4',
        lighter: '#8fe1f1',
        light: '#0DB8AE',
        normal: '#0DD1C5',
        dark: '#08ABA1',
        darker: '#178c84',
        strong: '#1c6f68',
        black: '#346870',
    },
    sand: {
        normal: '#fff0cb',
        darker: '#D99A00',
        lightSand: '#FFF5DC',
        darkSand: '#F2B718',
        darkestSand: '#625944',
    },
    state: {
        info: '#5C8FDC',
        success: '#50B061',
        warning: '#E9C052',
        error: '#EC465A',
    },
    error: {
        lightBg: '#FEF6F6',
        lightBg2: '#56DFD6',
        darkBg: '#56404F',
        darkBg2: '#3C4B5A',
        darkText: '#EBBBBB',
    },
    sidemenu: {
        light: '#81aac3',
        dark: '#9e99c4',
        hoverLight: '#b9e0dd',
    },
    gradient: {
        light: 'linear-gradient(90deg, #03D9B1 0%, #1CBBD9 100%)',
        dark: 'linear-gradient(90deg, #06C9A4 0%, #00A2C1 100%)',
        gray: 'linear-gradient(270deg, #ACB9BA 0%, #D1DDDD 108.89%)',
    },
    red: {
        disclaimer: '#A05A64',
    },
    transparent: 'transparent',
    paypal: {
        firstLetterLight: '#253B80',
        firstLetterDark: '#2D53CC',
        secondLetterLight: '#179BD7',
        secondLetterDark: '#25ACEA',
        colorBetweenLight: '#222D65',
        colorBetweenDark: '##162DA5',
        bgLight: '#f2f2f2',
        bgDark: '#192833',
    },
};

export { Palette };
export default Palette;
