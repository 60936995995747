import { AnalyticsInstance } from '@/common/service/analytics';
import { IPurchaseItem, TCRSActivityItem, TCRSAttraction, TCRSCartItem } from './domain';
import { IApiCartItem } from '@/entities/Cart/domain/Cart.domain';
import { ITERABLE_FORMAT_DATE, SERVER_FORMAT_DATE } from '../../constants/timeConstants';
import { addWeeks, format, parse, startOfDay } from 'date-fns';
import URL from '../urlsUtils';
import { getUser } from './requests';
import {
    IDestinationCategory,
    IDestinationList,
} from '@/entities/Destination/domain/Destination.domain';
import { IActivity } from '@/entities/Activity/domain/Activity.domain';
import { float } from '@/shared/Analytics/GA4/helpers';
import { getWpage } from '.';

export const convCartItemsToPurchaseItems = (cartItems: IApiCartItem[]) => {
    try {
        const items: IPurchaseItem[] = [];
        cartItems.forEach((item) => {
            Object.values(item.tickets).forEach((ticket) => {
                const date =
                    item.arrival_date &&
                    parse(item.arrival_date, SERVER_FORMAT_DATE, new Date()).getTime();
                items.push({
                    id: parseInt(ticket.id, 10).toString(),
                    name: [item.product_name, ticket.name].filter(Boolean).join(' - '),
                    description: ticket.description,
                    price: parseFloat(ticket.price),
                    quantity: parseInt(ticket.booked, 10),
                    imageUrl: `${URL.image}/activity/${item.product_id}/960x720/${item.product_slug}.jpg`,
                    url: `https://www.tripshock.com/${item.product_slug}/details/${item.product_id}/`,
                    dataFields: {
                        product_id: parseInt(item.product_id, 10),
                        arrival_date: date ? format(date, ITERABLE_FORMAT_DATE) : '',
                        departure_date: date
                            ? format(date + 86400 * 7 * 1000, ITERABLE_FORMAT_DATE)
                            : '',
                        name: item.product_name,
                        variant: ticket.name,
                        brand: item.partner_title,
                        price_strike_out: ticket.strike_out ? parseFloat(ticket.strike_out) : null,
                        destination: {
                            ...item.product_city_info,
                            id: parseInt(item.product_city_info.id, 10),
                        },
                        category: {
                            ...item.product_category_info,
                            id: parseInt(item.product_category_info.id, 10),
                        },
                    },
                });
            });
        });

        return items;
    } catch (err) {
        if (process.env.NODE_ENV === 'development') {
            console.error(err);
        }
        return [];
    }
};

export const createWishListObject = ({ id, slug }: { id: string | number; slug: string }) => ({
    product_id: typeof id === 'string' ? parseInt(id, 10) : id,
    originalPath: AnalyticsInstance.wpage,
    path: `/${slug}/details/${id}/`,
});

export const getItemIdsList = (cartItems: IApiCartItem[]) => {
    return cartItems.reduce((res, item) => {
        item.tickets.forEach((ticket) => {
            res[ticket.id] = item.id;
        });

        return res;
    }, {} as { [key: string]: string });
};

export const createUserInfoData = (withEmail?: boolean) => {
    const { email, firstName, lastName, phone, phone2, userId } = getUser();

    return {
        ...(withEmail && { email }),
        ...(firstName && { firstName }),
        ...(lastName && { lastName }),
        ...(phone && { phone }),
        ...(phone2 && { phone2 }),
        ...(userId && { userId }),
    };
};

export const createCRSCartItems = (cartItems: IApiCartItem[]): TCRSCartItem[] => {
    try {
        return cartItems.map(
            ({
                id,
                product_id,
                product_slug,
                arrival_date,
                product_name,
                partner_title,
                product_city_info,
                product_category_info,
                subtotal,
            }) => ({
                activityId: +product_id,
                brand: partner_title,
                cartItemId: id,
                imageUrl: `${URL.image}/activity/${product_id}/960x720/${product_slug}.jpg`,
                url: `https://www.tripshock.com/${product_slug}/details/${product_id}/`,
                name: product_name,
                price: subtotal,
                arrivalDate: createDate(arrival_date, 'arrival'),
                departureDate: createDate(arrival_date, 'departure'),
                destinationId: +product_city_info.id,
                destinationName: product_city_info.name,
                destinationSlug: product_city_info.slug,
                categoryId: +product_category_info.id,
                categoryName: product_category_info.name,
                categorySlug: product_category_info.slug,
            })
        );
    } catch (err) {
        if (process.env.NODE_ENV === 'development') {
            console.error(err);
        }
        return [];
    }
};

const createDate = (dateString: string, mode: 'arrival' | 'departure') => {
    const date = mode === 'departure' ? addWeeks(new Date(dateString), 1) : new Date(dateString);

    if (!date) {
        return 0;
    }

    const dateInSecs = Math.floor(date.getTime() / 1000);

    if (!dateInSecs) {
        return 0;
    }

    return dateInSecs;
};

export const createCRSActivityItem = (
    activity: IActivity,
    destination: IDestinationList,
    category?: IDestinationCategory | null
): TCRSActivityItem => {
    try {
        return {
            activityId: +activity.id,
            brand: activity.title,
            destinationId: +destination.id,
            destinationName: destination.name,
            destinationSlug: destination.slug,
            ...(!!category && {
                categoryId: +category.id,
                categoryName: category.name,
                categorySlug: category.slug,
            }),
            imageUrl: `${URL.image}/activity/${activity.id}/${activity.slug}.jpg`,
            name: activity.name,
            path: getWpage(),
            price: float(activity.price),
            priceStrikeOut: activity.price_strike_out ? float(activity.price_strike_out) : 0,
        };
    } catch (err) {
        if (process.env.NODE_ENV === 'development') {
            console.error(err);
        }
        return {} as TCRSActivityItem;
    }
};

export const createCRSAttraction = (
    destination: IDestinationList,
    category?: IDestinationCategory | null
): TCRSAttraction => {
    const date = startOfDay(new Date()).toString();

    try {
        return {
            arrivalDate: createDate(date, 'arrival'),
            departureDate: createDate(date, 'departure'),
            destinationId: +destination.id,
            destinationName: destination.name,
            destinationSlug: destination.slug,
            ...(!!category && {
                categoryId: +category.id,
                categoryName: category.name,
                categorySlug: category.slug,
            }),
        };
    } catch (err) {
        if (process.env.NODE_ENV === 'development') {
            console.error(err);
        }
        return {} as TCRSAttraction;
    }
};
