import { IDateRange } from '@/common/domain/Date.domain';
import { ICategory } from '@/common/service/api/Categories/Categories.domain';

import { IDestination } from '@/entities/Destination/domain/Destination.domain';
import { addDays, format, getMonth, parseISO } from 'date-fns';

export interface IDestinationCategory {
    counter: string;
    category?: ICategory;
}

export const getDestinationCategories = (
    categories: ICategory[],
    destination?: IDestination
): IDestinationCategory[] => {
    if (!destination) return [];
    const availableCategoriesIdx: { [item: string]: string } = destination.categories
        .split(',')
        .reduce((acc, curr) => {
            const categoryObject = curr.split(':');
            if (categoryObject[1] === '0') {
                return acc;
            }
            return {
                ...acc,
                [categoryObject[0]]: categoryObject[1],
            };
        }, {});
    return categories
        .filter((i) => !!availableCategoriesIdx[i.id])
        .map((i) => ({ counter: availableCategoriesIdx[i.id], category: i }));
};

export const getDatesFormatted = (dates?: IDateRange) => {
    const { dateStart, dateEnd } = dates ?? {};

    const defaultDateStart = new Date();
    const defaultDateEnd = addDays(defaultDateStart, 6);

    const currentDateStart = dateStart ?? defaultDateStart;
    const currentDateEnd = dateEnd ?? defaultDateEnd;

    const isSameMonth = getMonth(currentDateStart) === getMonth(currentDateEnd);

    const dateStartFormatted = format(
        typeof currentDateStart === 'string' ? parseISO(currentDateStart) : currentDateStart,
        'MMM dd'
    );
    const dateEndFormatted = format(
        typeof currentDateEnd === 'string' ? parseISO(currentDateEnd) : currentDateEnd,
        isSameMonth ? 'dd' : 'MMM d'
    );

    return { dateStartFormatted, dateEndFormatted, isSameMonth };
};
