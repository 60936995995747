import { LocalStorageTypes } from '../Storage.domain';
import { getCS as getCookie } from '../CookieStorage';

const LocalStorage = {
    get: (name: string, returnDefault?: string | number | unknown) => {
        if (!name?.length) {
            throw new Error('Invalid key.');
        }

        if (typeof window === 'undefined') {
            return;
        }

        const value = window.localStorage.getItem(name);
        try {
            if (value) {
                const parsed = JSON.parse(value);
                if (parsed) {
                    return parsed;
                }
            } else if (
                name === LocalStorageTypes.LS_CONTACT_INFO &&
                getCookie('iterableEndUserId')
            ) {
                // !TODO: No fixes values, no internal logic.
                setLS(
                    LocalStorageTypes.LS_CONTACT_INFO,
                    JSON.stringify({
                        email: getCookie('iterableEndUserId'),
                    })
                );

                return {
                    email: getCookie('iterableEndUserId'),
                };
            }
        } catch (e) {
            console.log(e);
        }

        return returnDefault;
    },
    set: (name: string, data?: string | number | unknown): boolean | void => {
        if (!name?.length) {
            throw new Error('Invalid key.');
        }

        if (!data) {
            return false;
        }
        window.localStorage.setItem(name, JSON.stringify(data));
        return true;
    },
    remove: (name: string) => window.localStorage.removeItem(name),
    clear: () => window.localStorage.clear(),
};

const getStorage = () => {
    return LocalStorage;
};

export const getLS = (name: string, returnDefault?: string | number | unknown) =>
    getStorage().get(name, returnDefault);

export const setLS = (name: string, data?: string | number | unknown) =>
    getStorage().set(name, data);
export const removeLS = (name: string) => getStorage().remove(name);
export const clearLS = () => getStorage().clear();
