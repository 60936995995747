import instance from '@/common/service/api/Axios';
import {
    TPartnersInfo,
    TPartnersLimit,
    TPartnerListDetails,
    TPartnerByIdValues,
    TPartnerById,
    TActivityPartnerById,
    TPartnerListProp,
} from './Partners.domain';
import { ApiUrls } from '@/common/domain/Endpoints.domain';

export const getHome = (params: TPartnersLimit) => {
    return instance
        .get<TPartnersInfo>(ApiUrls.partners.home, { params: { limit: params.limit } })
        .then(({ data }) => {
            if (data && typeof data === 'object' && data.meta && Array.isArray(data.destinations)) {
                return data;
            }

            throw new Error('Error');
        });
};

export const getPartner = async ({ destination_id, offset, limit }: TPartnerListProp) => {
    const { data } = await instance.get<TPartnerListDetails>(ApiUrls.partners.root, {
        params: { destination_id, offset, limit },
    });
    return data && typeof data === 'object' && Array.isArray(data.items) ? data : { items: [] };
};

export const getPartnerById = (params: string) => {
    return instance
        .get<TPartnerById>(ApiUrls.partners.root, { params: { id: params } })
        .then(({ data }) => {
            if (data.length > 1) {
                throw new Error(`[warn] More than one partners at list /partners/${params}`);
            }
            if (Array.isArray(data) && data[0]) {
                return data[0];
            }

            return null;
        });
};

const fetchActivityPartnerById = async (
    params: TPartnerByIdValues
): Promise<TActivityPartnerById> => {
    try {
        const { data } = await instance.get<TActivityPartnerById>(ApiUrls.activity.root, {
            params,
        });

        if (!data || !data.items) {
            throw new Error(`[warn] No activity at list /activity/${params}`);
        }
        return data;
    } catch (error) {
        return {
            meta: {
                title: '',
                description: '',
            },
            total: 0,
            activity_ids: '',
            items: [],
        };
    }
};

export const getActivityPartnerById = async (
    params: TPartnerByIdValues
): Promise<TActivityPartnerById> => {
    try {
        const data = await fetchActivityPartnerById({ ...params, limit: 10 });

        let activity_ids = data.activity_ids,
            items = data.items,
            offset = data.items.length,
            count = 0;
        const total = data.total;

        while (total > items.length && count < 10) {
            const more = await fetchActivityPartnerById({ ...params, offset, limit: 10 });
            activity_ids += more.activity_ids ? `,${more.activity_ids}` : '';
            items = [...items, ...more.items];
            offset += more.items.length;
            count += 1;
        }

        return {
            ...data,
            activity_ids,
            items,
        };
    } catch (error) {
        return {
            meta: {
                title: '',
                description: '',
            },
            total: 0,
            activity_ids: '',
            items: [],
        };
    }
};
