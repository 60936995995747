import { TKeyFrames } from './Theme.domain';

export const keyframes: TKeyFrames = {
    background0: 'hsl(200, 20%, 70%)',
    background100: 'hsl(200, 20%, 95%)',
    bgRotation0: 'rotate(0deg)',
    bgRotation100: 'rotate(360deg)',
};

export const Transition = {
    normal: `transition: .3s;`,
    arrow: `transition: 0.5s;`,
};
