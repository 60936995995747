/* eslint-disable camelcase */

import { Analytics } from '..';

export interface SubscribeProp {
    email: string;
}
export interface AccountData {
    firstName: string;
    lastName: string;
    phone: string;
    phone2: string;
    email: string;
    user_id: string;
    userId: string;
    picture: string;
}

export default function account(self: Analytics) {
    return {
        userId(userId: number | string) {
            const data = {
                event: 'userid',
                userId,
            };
            self.dataLayer(data);
            return self;
        },
        contactInfo(data: AccountData) {
            const dataForTrack = {
                event: 'account',
                action: 'contact_info',
                data,
            };
            self.dataLayer(dataForTrack);
            self.fbq('track', 'Contact');
            return self;
        },
        subscribe(data: AccountData) {
            const dataForTrack = {
                event: 'account',
                action: 'subscribed',
                data,
            };
            self.dataLayer(dataForTrack);
            self.fbq('track', 'Lead');
            return self;
        },
    };
}
