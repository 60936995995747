import getConfig from 'next/config';

export const isProd: boolean = process.env.BUILD_ENV === 'prod';

export const isTestProd = false;

export const isDevMode: boolean = process.env.NODE_ENV !== 'production';

export const isRuntime = (): boolean => getConfig().serverRuntimeConfig.isRuntime;

export const isWindow = (): boolean => typeof window === 'object';
