import { TPromoStatus } from '@/entities/Cart/domain/Cart.domain';
import { trackPromoCodeRequest } from './iterable';

const DEFAULT_MSG = 'Failed to apply discount to cart';

export const promoFailed = (
    setPromoStatus: (status: TPromoStatus) => void,
    err?: string | string[],
    promo?: string
) => {
    const isError = !!(err && Array.isArray(err));
    const errMsg = (isError ? err?.join(' - ') : err) || DEFAULT_MSG;

    setPromoStatus({
        isLoading: false,
        isError: isError,
        message: errMsg,
    });

    if (promo) {
        trackPromoCodeRequest({
            success: false,
            name: '',
            code: promo,
        });
    }
};
