import { IAttractionsSearch } from '../contexts/AttractionsContext';
import { getNewDateRange } from './dateUtils';

const MAX_RECENTS_ON_VIEW = 4;

export const getSearchRecents = (recents: IAttractionsSearch[]): IAttractionsSearch[] => {
    if (!recents) {
        return [];
    }

    const newRecents: IAttractionsSearch[] = recents
        ?.filter((recent, index, self) => {
            return (
                recent.destination &&
                index ===
                    self.findIndex(
                        (t) =>
                            t.destination.id === recent.destination.id &&
                            t.category?.id === recent.category?.id &&
                            t.partner?.id === recent.partner?.id
                    )
            );
        })
        .map((recent) => {
            const newDateRange = getNewDateRange(recent.dateRange);

            return {
                destination: recent.destination,
                category: recent.category,
                partner: recent.partner,
                dateRange: {
                    dateStart: newDateRange.dateStart,
                    dateEnd: newDateRange.dateEnd,
                },
            };
        });

    if (newRecents.length > MAX_RECENTS_ON_VIEW) {
        return newRecents.slice(0, MAX_RECENTS_ON_VIEW);
    }

    return newRecents;
};
